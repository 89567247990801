import React, { Component } from 'react';
import './App.css';
import netlifyIdentity from 'netlify-identity-widget'

class App extends Component {
  constructor() {
    super()

    this.handleLogIn = this.handleLogIn.bind(this)
  }

  handleLogIn () {
    netlifyIdentity.open()
  }

  render() {
    return (
      <div>
        <div>
          <h2>Smart Home</h2>
        </div>
        <div>
          <button onClick={this.handleLogIn} >Log in with netlify</button>
        </div>
      </div>
    );
  }
}

export default App;